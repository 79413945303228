import React, { FC } from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";

import { Podcast as PodcastObject, PodcastEpisode } from "lib/cms";

import PageWrapper from "components/PageWrapper";

import NotFoundPage from "pages/404";
import PortableText from "components/PortableText";
import FullWidth from "components/FullWidth";
import MetaData from "components/MetaData";

interface Props {
  data: { podcast: PodcastObject };
  location: Location;
}

const Podcast: FC<Props> = ({ data: { podcast } }) => {
  return podcast ? (
    <PageWrapper backgroundColor="grey">
      <MetaData
        title={`${podcast.name} | Podcasts`}
        description={podcast.tagline
          .map(block => block.children.map(child => child.text).join(" "))
          .join(" ")}
        image={podcast.coverImage.asset.fluid.src}
      />
      <FullWidth>
        <div className="relative">
          <GatsbyImage fluid={podcast.coverImage.asset.fluid} />
          {/* <div className="text-white font-black absolute inset-0 flex flex-col items-center justify-center text-center pb-4">
            <h1 className="uppercase text-large">{podcast.name}</h1>
            <h3 className="text-medium">{podcast.episodes.length} Episodes</h3>
          </div> */}
        </div>
      </FullWidth>
      <div className="py-2.5 md:py-9" />
      <PortableText
        blocks={podcast.description}
        className="text-medium md:text-intro font-medium"
      />
      <ul className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 mt-10">
        {podcast.episodes.map(episode => (
          <Episode episode={episode} podcast={podcast} />
        ))}
      </ul>
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

const Episode: FC<{ episode: PodcastEpisode; podcast: PodcastObject }> = ({
  episode,
  podcast,
}) => {
  const imageMarkup = (
    <GatsbyImage fluid={(episode.thumbnail || podcast.thumbnail).asset.fluid} />
  );
  return (
    <li key={episode._key}>
      {episode.appleLink ? (
        <a href={episode.appleLink} target="_blank" rel="noreferrer noopener">
          {imageMarkup}
        </a>
      ) : (
        imageMarkup
      )}
      <hgroup className="font-arial text-mobile-tiny md:text-tiny mt-1.4 md:mt-3 mb-1 md:mb-0">
        <h5 className="text-grey_1">Episode {episode.number}</h5>
        <h4 className="font-bold mt-0.4 md:mt-1">{episode.title}</h4>
      </hgroup>
    </li>
  );
};

export const query = graphql`
  query($slug: String!) {
    podcast: sanityPodcast(slug: { current: { eq: $slug } }) {
      _id
      name
      slug {
        current
      }
      description: _rawDescription
      tagline: _rawTagline
      coverImage {
        asset {
          _id
          fluid(maxWidth: 3000) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      thumbnail {
        asset {
          _id
          fluid(maxWidth: 560) {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      episodes {
        _key
        title
        number
        appleLink
        thumbnail {
          asset {
            _id
            fluid(maxWidth: 560) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
        description: _rawDescription
      }
    }
  }
`;

export default Podcast;
